
export const loadFromLocalStorage = () => {
    const fromStorage = localStorage.getItem( 'state' );
    if( !fromStorage ) {
        return;
    }

    return JSON.parse( fromStorage );
};

export const createImage = ( state ) => {

    // Schritt 1: Das Canvas-Element erstellen
    const canvas = document.createElement('canvas');
    canvas.width = canvas.height = state.backgroundWidth;
    const ctx = canvas.getContext('2d');

    // Schritt 2: Das Hintergrundbild laden
    const backgroundImage = new Image();
    backgroundImage.src = state.mainBackground; // Passe den Pfad zum Hintergrundbild an

    // Warte auf das Laden des Hintergrundbildes
    backgroundImage.onload = function() {
        // Schritt 3: Das Hintergrundbild zeichnen
        ctx.drawImage( backgroundImage, 0, 0, state.backgroundWidth, state.backgroundWidth );

        // Schritt 4: Den Text zeichnen
        ctx.font = state.logoFontSize + 'pt ' + state.logoFont;
        ctx.fillStyle = state.logoColor; // Textfarbe
        var text = state.logoName;
        // ctx.strokeStyle = state.logoColor;
        // ctx.lineWidth = state.logoBorderWidth;
        // ctx.strokeText( text, state.logoLeft - state.logoOffsetLeft, state.logoOffsetTop );
        // ctx.fillText(text, state.logoLeft - state.logoOffsetLeft +40, state.logoOffsetTop -160);
        ctx.fillText(text, state.logoOffsetLeft + 50 , state.logoOffsetTop + 100 );

        // Schritt 5: Das fertige Bild als Daten-URL abrufen
        var dataURL = canvas.toDataURL();

        // Schritt 6: Das Bild in ein HTML-Element einfügen
        var link = document.createElement('a');
        link.href = dataURL;
        link.download = 'bild.png'; // Dateiname festlegen

        // Schritt 5: Den Link programmatisch anklicken, um den Download zu starten
        link.click();
    };
};

export const exportSetting = ( state ) => {
    const blob = new Blob([state], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.download = "logo-settings.json";
    link.href = url;
    link.click();
};

export const addTimeStamp = ( str ) => str + '?' + Date.now(); 