import React from "react";
import { HashLink } from 'react-router-hash-link';
import {useTranslation} from 'react-i18next';
import { addTimeStamp } from "../utils";

export const TopNavigation = () => {
    const {t, i18n} = useTranslation();

    const style = {
        width: '30px',
        height: '3px',
        backgroundColor: '#f0edbb',
        margin: '6px 0',
        cursor: 'pointer'
    }

    const responsiveMenu = () => {
        return (
        <div id="burger-icon" className="dropdown">
            <div style={style}></div>
            <div style={style}></div>
            <div style={style}></div>
            <div className="dropdown-content">
                <a href="#en" onClick={ () => i18n.changeLanguage('en') }>🇺🇸</a>
                <a href="#pt" onClick={ () => i18n.changeLanguage('pt') }>🇵🇹</a>
                <a href="#it" onClick={ () => i18n.changeLanguage('it') }>🇮🇹</a>
                <a href="#de" onClick={ () => i18n.changeLanguage('de') }>🇩🇪</a>
            </div>
        </div>
        );
    };

    return (
        <div className='sticky'>
            <ul>
                <li><HashLink smooth to='/#top'><img id='logo-spin' src={addTimeStamp("assets/amberane-logo-sepia.png")} alt="ornament" ></img></HashLink></li>
                <li><HashLink smooth to='/#band' ><span>{ t( 'navigation.band' ) }</span></HashLink></li>
                <li><HashLink smooth to='/#stream' ><span>{ t( 'navigation.media' ) }</span></HashLink></li>
                <li><HashLink smooth to='/#contact' ><span>{ t( 'navigation.shop' ) }</span></HashLink></li>        
                <li>
                    {responsiveMenu()}
                </li>        
            </ul>
        </div>
    );
};