export const resources = {
    de: {
      translation: {
          home: {
              "welcome_message": "Willkommen im AMBERANE Hauptquartier",
              "get_access_button": "Zugriff erhalten",
              "teaser-text-video-above": "Check out our new video 'Footsteps'",
              "teaser-text-video-below": "Vom neuen Album 'Far From Home' - erhältlich ",
              "teaser-text-video2-above": "Check out our new video 'Still good'",
              "teaser-text-video2-below": "Vom neuen Album 'Far From Home' - erhältlich "
          },
          band: {
              "bio_text": "2021.<br />Eine Pandemie bringt die Welt zum Stillstand.<br />Bands fallen auseinander.<br />Alles ist verwirrend.<br />Bino und Oliver brauchen kreativen Output.<br />Sie fangen an zu jammen, finden ein Ziel.<br />Aber sie finden keine anderen Leute.<br />Alles ist frustrierend.<br />Bis Lorenzo vorbeikommt.<br />Buchstäblich einen Tag später taucht Jens auf.<br />Alles wird besser.<br />Sie fangen an zu jammen, sind sich einig.<br />Amberane ist geboren.<br />Songs schreiben, eine Show spielen, ein Album aufnehmen.<br />Alles ist hektisch.<br />Spulen Sie vor in 2024:  Far From Home wird veröffentlicht.<br />Und alles ist gut." 
          },
          media: {
            "music_view": "Musik",
            "band_gallery": "Fotos",
            "music_new_album": "Neues Album 'Far From Home' - erhältlich ",
            "music_new_album_date": "ab sofort" 
          },
          shop: {
            "album-teaser-text": "Neues Album 'Far From Home' - erhältlich "
          },
          navigation:{
              "shop": "Kontakt",
              "band": "Kapelle",
              "media": "Medien"
          },
          components: {
              language: {
                  "de": "Deutsch",
                  "en": "Englisch",
                  "pt": "Portugisisch",
                  "it": "Italienisch",
              }
          }
      }
    },
    en: {
      translation: {
          home: {
              "welcome_message": "Welcome to AMBERANE Headquater",
              "get_access_button": "Get Access",
              "teaser-text-video-above": "Check out our new video 'Footsteps'",
              "teaser-text-video-below": "Taken from the album 'Far From Home' - out ",
              "teaser-text-video2-above": "Check out our new video 'Still good'",
              "teaser-text-video2-below": "Taken from the album 'Far From Home' - out "              
          },
          band: {
              "bio_text": "2021.<br />A pandemic brings the world to a stop.<br />Bands fall apart.<br />Everything is confusing.<br />Bino and Oliver need creative output.<br />Start jamming, find purpose.<br />But can't find other people.<br />Everything is frustrating.<br />Until Lorenzo swings by.<br />Literally a day later, Jens pops in.<br />Everything is looking up.<br />Start jamming, birds of a feather.<br />Amberane is born.<br />Write songs, play a show, record an album.<br />Everything is hectic.<br />Fast-forward to 2024:  Far From Home gets released.<br />And everything is good." 
          },
          media: {
            "music_view": "Music",
            "band_gallery": "Pictures",
            "music_new_album": "New album 'Far From Home' - out ",
            "music_new_album_date": "now" 
          },
          shop: {
            "album-teaser-text": "New Album 'Far From Home' - out "
          },
          navigation:{
              "shop": "Contact",
              "band": "Band",
              "media": "Media"
          },
          components: {
              language: {
                  "de": "German",
                  "en": "English",
                  "pt": "Portuguese",
                  "it": "Italian",
              }
          }
      },
    },
    pt: {
      translation: {
          home: {
              "welcome_message": "Bem-vindo à sede da AMBERANE",
              "get_access_button": "Obter Acesso",
              "teaser-text-video-above": "Check out our new video 'Footsteps'",
              "teaser-text-video-below": "Taken from the album 'Far From Home' - coming ",
              "teaser-text-video2-above": "Check out our new video 'Still good'",
              "teaser-text-video2-below": "Taken from the album 'Far From Home' - out "                  
          },
          band: {
              "bio_text": "2021.<br />Uma pandemia faz parar o mundo.<br />As bandas desmoronam-se.<br />Tudo é confuso.<br />Bino e Oliver precisam de uma saída criativa.<br />Comecem a tocar, encontrem um objetivo.<br />Mas não conseguem encontrar outras pessoas.<br />Tudo é frustrante.<br />Até o Lorenzo aparecer.<br />Literalmente um dia depois, aparece o Jens.<br />Tudo melhora.<br />Começam a tocar, pássaros de uma só pena.<br />Nasce a Amberane.<br />Escrevem canções, dão um concerto, gravam um álbum.<br />Tudo está agitado.<br />Até de 2024:  Far From Home é lançado.<br />E tudo é bom." 
          },
          media: {
            "music_view": "Música",
            "band_gallery": "Imagens",
            "music_new_album": "Novo álbum 'Far From Home' já está disponível!",
            "music_new_album_date": "now" 
          },
          shop: {
            "album-teaser-text": "New Album 'Far From Home' - coming "
          },
          navigation:{
              "shop": "Contactar",
              "band": "Banda",
              "media": "Media"
          },
          components: {
              language: {
                  "de": "Alemão",
                  "en": "Inglês",
                  "pt": "Portuguese",
                  "it": "Italian",
              }
          }
      },
    },
    it: {
      translation: {
          home: {
              "welcome_message": "Benvenuti alla sede centrale di AMBERANE",
              "get_access_button": "Ottenere l'accesso",
              "teaser-text-video-above": "Check out our new video 'Footsteps'",
              "teaser-text-video-below": "Taken from the album 'Far From Home' - coming ",
              "teaser-text-video2-above": "Check out our new video 'Still good'",
              "teaser-text-video2-below": "Taken from the album 'Far From Home' - out "                  
          },
          band: {
              "bio_text": "2021.<br />Una pandemia ferma il mondo.<br />Le band si sciolgono.<br />Tutto è confuso.<br />Bino e Oliver hanno bisogno di una produzione creativa.<br />Iniziano a fare jamming, trovano uno scopo.<br />Ma non riescono a trovare altre persone.<br />Tutto è frustrante.<br />Fino a quando non passa Lorenzo.<br />Letteralmente un giorno dopo, Jens fa capolino.<br />Tutto va per il meglio.<br />Iniziano a suonare, sono come due uccelli.<br />Nasce Amberane.<br />Scriviamo canzoni, facciamo uno spettacolo, registriamo un album.<br />Tutto è frenetico.<br />Fast forward to 2024:  Esce Far From Home.<br />E tutto va bene." 
          },
          media: {
            "music_view": "Musica",
            "band_gallery": "Immagini",
            "music_new_album": "In uscita il nuovo album 'Far From Home'",
            "music_new_album_date": "now" 
          },
          shop: {
            "album-teaser-text": "New Album 'Far From Home' - coming "
          },
          navigation:{
              "shop": "Contattateci",
              "band": "Gruppo",
              "media": "Media"
          },
          components: {
              language: {
                  "de": "Tedesco",
                  "en": "Inglese",
                  "pt": "Portoghese",
                  "it": "Italiano",
              }
          }
      },
    }
  };