import React from "react";
import { addTimeStamp } from "../utils";

export const Member = ( props ) => {
    const { name, role, image } = props;
    return (
        <div>
            <p>{name} ({role})</p>
            <img src={addTimeStamp( `assets/${image}.png` )} alt={image}/>
        </div>
    );
};