import React from "react";
import {Trans} from 'react-i18next';
import { Member } from "../components/member";
import { addTimeStamp } from "../utils";

export const BandView = () => {
    return(
        <div className="main-view band-view">
            <div className="opaque-layer band-bio" style={{backgroundImage: `url(${addTimeStamp("assets/band-background-bw2.jpg")})`, backgroundColor: '#7a3d00'}}>
                <Trans i18nKey="band.bio_text"></Trans>
            </div>
            <div className='member-box'>
                <Member name="Lorenzo" role="Vocals" image="1_lorenzo-opt" />
                <Member name="Oliver Fazio" role="Guitars" image="1_oli-opt" />
                <Member name="Sabino Azevedo" role="Drums" image="1_bino-opt" />
                <Member name="Jens Stuffertz" role="Bass" image="1_jens-opt" />
            </div>
        </div>
    );
}