import React from "react";

const defaultGlobalState = {
    currentSong: undefined,
    currentGallery: 'music',
    mainLogo: '',
    mainBackground: 'assets/amberane-main-logo.jpg',
    logoWidth: 500,
    logoName: 'AMBERANE',
    logoFontSize: 60,
    logoFont: 'AQUIRE',
    logoColor: 'white',
    logoBorderColor: 'white',
    backgroundWidth: 1200,
    logoBorderRadius: 24,
    logoBorderStyle: 'double',
    logoBorderWidth: 20,
    logoBackgroundIsTransparent: true,
    isBackgroundFixed: false
};
const GlobalStateContext = React.createContext(defaultGlobalState);
const DispatchStateContext = React.createContext(undefined);

export const GlobalStateProvider = ({ children }) => {
    const [state, dispatch] = React.useReducer(
        (state, newValue) => ({ ...state, ...newValue }),
        defaultGlobalState
    );
    return (
        <GlobalStateContext.Provider value={state}>
            <DispatchStateContext.Provider value={dispatch}>
                {children}
            </DispatchStateContext.Provider>
        </GlobalStateContext.Provider>
    );
};

export const useGlobalState = () => [
  React.useContext(GlobalStateContext),
  React.useContext(DispatchStateContext)
];