import React from "react";
import { ImageGalleryView } from "../components/imageGallery";

const bandPictureContext = require.context( '../media/band', false, /\.jpg|\.png$/ );
const bandPictures = {
    images: bandPictureContext.keys().map( image => bandPictureContext( image ) ),
    allowEdit: false
};

export const PictureView = () => {

    return (
        <div>
            <ImageGalleryView gallery={bandPictures} />
        </div>
    );
};