import React from "react";

export const Impressum = () => {
    return(
        <div id="impressum" className="impressum">
            
            <h2>Amberane Impressum</h2>
            <p>Angaben gemäß § 5 TMG:</p>
            <p>
            <strong>Bandname:</strong> Amberane<br/>
            <strong>Vertreten durch:</strong> Jens Stuffertz<br/>
            <strong>Adresse:</strong> Gielenstrasse 61, 41460 Neuss<br/>
            <strong>Kontakt:</strong> <br/>
            Telefon: 01234/666-969-dontcalluswecallyou<br/>
            E-Mail: massentonhaltung@gmail.com<br/>
            </p>
            <p>
            <strong>Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:</strong><br/>
            Jens Stuffertz
            </p>

            <h2>Datenschutzerklärung</h2>
            <p>
            <strong>1. Datenschutz auf einen Blick</strong><br/>
            Allgemeine Hinweise<br/>
            Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten passiert, wenn Sie unsere Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie persönlich identifiziert werden können.
            </p>
            <p>
            <strong>2. Datenerfassung auf unserer Website</strong><br/>
            Wer ist verantwortlich für die Datenerfassung auf dieser Website?<br/>
            Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Dessen Kontaktdaten können Sie dem Impressum dieser Website entnehmen.
            </p>
            <p>
            <strong>3. Wie erfassen wir Ihre Daten?</strong><br/>
            Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es sich z.B. um Daten handeln, die Sie in ein Kontaktformular eingeben.
            </p>
            <p>
            <strong>4. Wofür nutzen wir Ihre Daten?</strong><br/>
            Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website zu gewährleisten. Andere Daten können zur Analyse Ihres Nutzerverhaltens verwendet werden.
            </p>
            <p>
            <strong>5. Welche Rechte haben Sie bezüglich Ihrer Daten?</strong><br/>
            Sie haben jederzeit das Recht, unentgeltlich Auskunft über Herkunft, Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu erhalten. Sie haben außerdem ein Recht, die Berichtigung oder Löschung dieser Daten zu verlangen. Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie sich jederzeit unter der im Impressum angegebenen Adresse an uns wenden.
            </p>

            <h2>Urheberrecht</h2>
            <p>
            Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
            </p>
            <p>
            Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
            </p>

            <h2>Haftungsausschluss</h2>
            <p>
            <strong>Haftung für Inhalte</strong><br/>
            Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
            </p>
            <p>
            Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
            </p>
            <p>
            <strong>Haftung für Links</strong><br/>
            Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar.
            </p>
            <p>
            Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.
            </p>

            <h2>Cookie-Richtlinie</h2>
            <p>
            Unsere Website verwendet Cookies. Cookies sind kleine Textdateien, die auf Ihrem Endgerät gespeichert werden und die Ihr Browser speichert. Sie richten keinen Schaden an. Wir verwenden Cookies, um unser Angebot nutzerfreundlicher, effektiver und sicherer zu machen.
            </p>
            <p>
            Einige Cookies sind essenziell für den Betrieb der Website, andere helfen uns, die Website und Ihr Nutzererlebnis zu verbessern. Sie können Ihre Cookie-Einstellungen jederzeit anpassen.
            </p>
            <p>
            Bei Ihrem ersten Besuch unserer Website wird ein Cookie-Banner angezeigt, das Sie über die Verwendung von Cookies informiert und um Ihre Einwilligung bittet. Sie haben die Möglichkeit, der Nutzung von Cookies zuzustimmen oder diese abzulehnen.
            </p>
            <p>
            Weitere Informationen zu den von uns verwendeten Cookies und die Möglichkeit, Ihre Zustimmung zu verwalten, finden Sie in unserer <a href="datenschutz.html">Datenschutzerklärung</a>.
            </p>
        </div>
    );
};
