import React from "react";

import Flickity from 'react-flickity-component'

const flickityOptions = {
    groupCells: true,
    wrapAround: true
}

export const ImageGalleryView = ( props ) => {
    const {gallery} = props;

    const elements = gallery.images.map( (image, index) => 
        <div className="carousel-cell" key={index}><img src={image} alt='img' loading="lazy" /></div>
    );

    return(

        <Flickity
            className={'carousel'}
            elementType={'div'}
            options={flickityOptions}
            disableImagesLoaded={false}
            reloadOnUpdate
            static={true}
            >
             {elements}
        </Flickity>

    );

};