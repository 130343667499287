import React from "react";
import { Impressum } from "./impressum";
import ExpandablePanel from "../components/expandablePanel";
import { SocialLinks } from "../components/socialLinks";

export const Footer = () => {
    return (
        <div id='footer' className='app-footer'>
            <a href="#top"><i className="arrow up"></i></a>
            <div style={{paddingTop: '20pt', display: 'ruby'}}>
                <SocialLinks />
            </div>
            <div className='copy'>
                <ExpandablePanel headerText={'Impressum'} children={<Impressum />} scrollToId={'impressum'} />
                <p>&copy;{new Date().getFullYear()} AMBERANE</p>
            </div>
        </div>
    );
}