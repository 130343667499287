import React from "react";
import { useTranslation } from 'react-i18next';

export const ShopView = () => {
    const {t} = useTranslation();
    return (
        <div className="main-view shop-view">
            <p>{t('shop.album-teaser-text')}</p>
            <img src="assets/album-cover.png" alt="cover" />
            
            <img src="assets/paypal-yellow.png" alt="pay" style={{width: '10em'}} />
        </div>
    );
};