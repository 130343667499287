import React from "react";

export const SocialLinks = () => {
    return(
        <ul className="social-links">
            <li>
                <a href="https://www.facebook.com/profile.php?id=61558796641841" target="_blank" rel="noreferrer">
                    <i className="fab fa-facebook fab-facebook"></i>
                </a>
            </li>
            <li>
                <a href="https://www.instagram.com/amberane.music/" target="_blank" rel="noreferrer">
                    <i className="fab fa-instagram fab-instagram"></i>
                </a>
            </li>
            <li>
                <a href="https://www.youtube.com/@Amberane-Music" target="_blank" rel="noreferrer">
                    <i className="fab fa-youtube fab-youtube"></i>
                </a>
            </li>
        </ul>
    );
};