import React from "react";
import {useTranslation} from 'react-i18next';

export const MusicView = () => {
    const {t} = useTranslation();
    return(
        <div className="main-view media-music">
            <div className='opaque-layer discografie'>
                <img src="assets/album-back.png" alt="album-front" style={{width:'35vw'}}></img>
                <div style={{textAlign: 'left'}}>
                    <p style={{textTransform: 'uppercase', fontWeight: 'bold', margin: 0, textAlign: 'center'}}>{ t( 'media.music_new_album' ) }</p>
                    <ol>
                        <li>Alpha (1:47)</li>
                        <li>Footsteps (3:48)</li>
                        <li>Still good (4:23)</li>
                        <li>
                            <div style={{display: 'flex', flexDirection: 'column'}}>
                                <span>What if (5:20)</span>
                                <audio controls playsInline style={{height: 18, backgroundColor: 'coral'}}>
                                    <source src='audio/whatif-web.mp3'/>
                                </audio>
                            </div>

                        </li>
                        <li>Circle (6:48)</li>
                        <li>Never enough (4:01)</li>
                        <li>Stands to reason (4:30)</li>
                        <li>Someone like you (4:43)</li>
                        <li>Walls (3:20)</li>
                        <li>Unbroken (4:31)</li>
                    </ol>
                </div>
            </div>
        </div>
    );
}