import React, { useState, useEffect } from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css';

const ExpandablePanel = ( { headerText, children, scrollToId } ) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const togglePanel = () => {
        setIsExpanded( !isExpanded );
        if ( !isExpanded && scrollToId ) {
            window.location.hash = scrollToId;
        }
    };

    useEffect( () => {
        if ( isExpanded && scrollToId ) {
            const element = document.getElementById( scrollToId );
            if ( element ) {
                element.scrollIntoView( { behavior: 'smooth', block: 'start' } );
            }
        }
    }, [isExpanded, scrollToId] );

    return (
        <div style={styles.panel}>
            <div style={styles.header} onClick={togglePanel}>
                <span>{headerText}</span>
                <i className={`fas fa-chevron-${isExpanded ? 'up' : 'down'}`} style={styles.icon}></i>
            </div>
            {isExpanded && (
                <div style={styles.content}>
                {children}
                </div>
            )}
        </div>
    );
};

const styles = {
  panel: {
    borderRadius: '4px',
    margin: '10px 0',
    flexDirection: 'column'
  },
  header: {
    padding: '10px',
    cursor: 'pointer',
    userSelect: 'none',
  },
  icon: {
    marginLeft: '10px',
  },
  content: {
    padding: '10px',
  },
};

export default ExpandablePanel;
