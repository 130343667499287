import React from 'react';
import ReactDOM from 'react-dom/client';
import { Music, Band, Media, Navigation, Footer, Pictures, Shop, SinglePage} from './views';
import { MemoryRouter, Route, Routes } from 'react-router-dom';
import './i18n';
import './index.css';
import { GlobalStateProvider } from './GlobalState';

const App = () => {
  return (
      <GlobalStateProvider>
        <MemoryRouter>
          <Navigation/>
          <Routes>
            <Route path='/' element={<SinglePage />}/>
            <Route path='home' element={<SinglePage />}/>
            <Route path='shop' element={<Shop />}/>
            <Route path='band' element={<Band />}/>
            <Route path='media' element={<Media />} >
              <Route index element={<Music />} />
              <Route path='music' element={<Music />} />
              <Route path='pictures' element={<Pictures />} />
            </Route>
          </Routes>
          <Footer/>
        </MemoryRouter>
      </GlobalStateProvider>
  );
}

const root = ReactDOM.createRoot( document.getElementById( 'root' ) );
root.render(
  <React.StrictMode>
    <div className="App">
      <App/>
    </div>
  </React.StrictMode>
);