import React from "react";
import { NavLink, Outlet } from 'react-router-dom';
import {useGlobalState} from "../GlobalState"
import {useTranslation} from 'react-i18next';

export const MediaView = () => {
    const [state,dispatch] = useGlobalState();
    const {t} = useTranslation();
    
    const wasActive = ( name ) => state.currentGallery === name;

    const MediaNavigation = () => 
        <div id="image-box-nav" className="sticky">
            <ul style={{gap: 20, padding: 5, justifyContent: 'center'}}>
                <NavLink className={({ isActive }) => (isActive || wasActive('music')) ? 'active' : ''} to="music" onClick={() => dispatch({ currentGallery: 'music'})}>{t('media.music_view')}</NavLink>
                <NavLink className={({ isActive }) => (isActive || wasActive('pictures')) ? 'active' : ''} to="pictures" onClick={() => dispatch({ currentGallery: 'pictures'})}>{t('media.band_gallery')}</NavLink>
            </ul>
        </div>;

    return (
        <div>
            <MediaNavigation />
            <Outlet />
        </div>
    );
};